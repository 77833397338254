import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
// Components
import VideoLayout from 'components/VideoLayout'
// Hooks
import useWindowSize from 'hooks/useWindowSize'
const videos = require("../../data/publications")

const Blog = () => {
  const windowSize = useWindowSize()
  return (
    <VideoLayout
      title='Videos'
      posts={videos.YouTube}
      categories={[]}
      windowSize={windowSize}
    />
  )
}


export default Blog
